.tooltip {
  pointer-events: auto;

  &__tooltip-content {
    display: flex;
  }

  .uui-tooltip-body {
    pointer-events: auto;
    max-width: unset !important;
    padding: 0 !important;
  }
}
