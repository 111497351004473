@import "SCSS/colors";
@import "SCSS/partials/mixins";

.badge {
  @include font(12px, 400, $gray70, 12px);

  display: inline-block;
  background-color: $gray10;
  border: 1px solid $gray30;
  border-radius: 30px;
  padding: 2px 6px !important;

  &__button {
    align-items: center;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    display: flex;
    justify-content: center;
    outline: none;
    padding: 0;

    path {
      fill: $gray60;
    }
  }

  &--isGroupName {
    padding-left: 3px;
  }

  &--white {
    background-color: #fff;
  }

  &--blue {
    background-color: $white-blue;
    border-color: $light-blue;
    color: $blue;

    path {
      fill: $blue;
    }
  }

  &--amber {
    background-color: $white-amber;
    border-color: $light-amber;
    color: $amber;

    path {
      fill: $amber;
    }
  }

  &--grass {
    background-color: $white-grass;
    border-color: $light-grass;
    color: $grass;

    path {
      fill: $grass;
    }
  }

  &--favorite,
  &--certified {
    .badge__label {
      text-transform: capitalize;
    }

    path {
      fill: #fff;
    }
  }

  &--certified {
    background-color: $certified-tag-blue;
    border-color: $certified-tag-blue;
    color: #fff;
  }

  &--favorite {
    background-color: $favorite-tag-orange;
    border-color: $favorite-tag-orange;

    .badge__icon {
      svg {
        width: 20px;
        height: 20px;
      }
    }

    .badge__button {
      path {
        fill: $gray70;
      }
    }
  }
}
