@import "SCSS/colors";
@import "SCSS/partials/variables";
@import "SCSS/partials/mixins";

.reports-browser {
  &__wrapper {
    margin-bottom: 20px;

    .reports-browser-filter {
      margin-right: 24px;
    }

    .report-view-toggle {
      margin-left: 24px;
    }
  }

  &__filter-badges {
    --gap: 6px;

    margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));

    .badge {
      align-items: center;
      border-radius: 18px !important;
      display: inline-flex;
      flex-shrink: 0;
      margin: var(--gap) 0 0 var(--gap);
      padding-left: 7px;
      padding-right: 6px;
      /* stylelint-disable */
      .uui-caption {
        @include font(14px, 400, #fff, 24px);
      }

      &__label {
        font-size: 14px;
        line-height: 24px;
        padding: 0 5px;
      }
    }
  }

  &__content-wrapper {
    float: right;
    width: 100%;

    &--cards {
      .reports-browser__filter-badges {
        margin-bottom: 12px;
      }
    }
  }

  &__loader {
    height: 100%;
    left: 0;
    opacity: 0.5;
    top: 0;
    width: 100%;
    z-index: 100;

    &-icon {
      left: calc(50% - 54px);
      position: fixed;
      top: calc(50% - 54px);
      z-index: 100;

      &--isCardMode {
        left: calc(50% - 54px + 175px);
      }

      > svg {
        height: 108px;
        width: 108px;
      }
    }
  }

  @include screen-max($tablet) {
    &__wrapper {
      flex-wrap: wrap;
    }
  }
}
