@import "SCSS/colors";
@import "SCSS/partials/variables";
@import "SCSS/partials/mixins";

.path-select-modal {
  &__content {
    border-radius: 0 !important;
    margin: 30px 24px;
    min-height: 50px;
    padding: 0;
    position: relative;
  }

  &__folder-icon {
    margin-right: 8px;
  }
}

.rc-tree {
  .rc-tree-treenode-disabled {
    .rc-tree-switcher {
      pointer-events: none;
    }
  }

  .rc-tree-treenode {
    &:hover {
      background-color: $gray10;
    }

    .rc-tree-node-content-wrapper {
      margin-top: 2px !important;
    }

    .rc-tree-switcher,
    .rc-tree-iconEle {
      background-image: none !important;
      height: 24px !important;
      line-height: 24px !important;
      width: 24px !important;
    }

    .rc-tree-node-selected {
      background-color: $night50;
      box-shadow: 0 0 0 1px $night400;
    }

    .rc-tree-title {
      @include font(14px, 400, $gray70, 24px, 0.1px);

      height: 100%;
      margin-left: 5px;
      padding-right: 5px;
      vertical-align: top;
    }
  }

  .rc-tree-child-tree {
    display: block;
  }

  .node-motion {
    overflow-y: hidden;
    transition: all 0.3s;
  }
}
