@import "SCSS/colors";
@import "SCSS/partials/mixins";
@import "SCSS/partials/variables";

.dashboard-section {
  --gap: 24px;

  position: relative;
  min-height: calc(2 * var(--gap) + 30px);
  margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));
  width: calc(100% + var(--gap));

  &__title {
    .uui-caption {
      @include font(21px, 600, $night700, 24px);
    }

    &--isDisabled {
      .uui-caption {
        pointer-events: none;
        color: $night500;
      }
    }

    &:hover {
      .uui-caption {
        text-decoration: underline;
      }
    }
  }

  &__loader {
    position: absolute;
    z-index: 1;
    left: calc(50% - 45px);
  }
}
