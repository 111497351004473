.drag-handle {
  touch-action: none;
  flex: 0 0 auto;
  padding: 15px;
  cursor: grabbing;
  border: none;
  outline: none;
  appearance: none;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;

  svg {
    flex: 0 0 auto;
    fill: #fff;
    margin: auto;
    height: 100%;
    overflow: visible;
  }
}
