@import "SCSS/partials/variables";
@import "SCSS/partials/mixins";
@import "SCSS/colors";

.reports-browser-search {
  @include search-input;

  width: 100%;
  max-width: $all-reports-search-width;

  &__input {
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
}
