@import "SCSS/colors";
@import "SCSS/partials/mixins";

.synonym-words {
  background-color: $night200;
  border-radius: 3px;
  margin-top: 3px;
  padding: 3px 6px;

  &__suffix {
    @include font(12px, 400, $night600, 18px);

    align-self: flex-start;
    flex-shrink: 0;
    margin-right: 3px;
  }

  &__content {
    @include font(12px, 400, $night900, 18px);
  }
}
