@import "@App/scss/colors";
@import "SCSS/partials/mixins";
@import "SCSS/partials/variables";

.not-found-alert {
  margin: auto;

  > * {
    margin-right: 5px;
  }
}

.add-report-form {
  margin: 18px 24px 30px;

  &__row {
    .switch-cell {
      padding-top: 37px;
    }

    .upload-file-input {
      &__wrapper {
        display: flex;
        width: 422px;
        flex-wrap: wrap;
      }

      /* stylelint-disable */
      &__labeled-input {
        .uui-label-top {
          align-items: center;
          display: flex;
          flex-wrap: wrap;
        }

        .uui-input-box {
          flex-basis: calc(100% - 42px) !important;
        }

        .uui-button-box {
          margin-left: auto;
        }
      }

      &__invalid {
        border-color: #ff4242 !important;
      }
      &__error-text {
        width: 400px;
      }

      &__icon-button {
        border: 1px solid $gray40;
        border-radius: 3px;
        height: 36px;
        width: 36px;
        align-items: center;
        display: flex;
        justify-content: center;

        &:hover {
          border-color: $gray60;

          g {
            fill: $gray60;
          }
        }
      }
      /* stylelint-enable */
    }
  }

  &__radio-group {
    > *:not(:first-child) {
      margin-left: 22px !important;
    }

    &-wrapper {
      margin: 8px 0 0 1px;
    }
  }

  .tooltip-icon {
    svg {
      margin-left: 3px;
      height: 20px;
      width: 20px;
      fill: #acafbf;
    }
  }

  @include screen-max($mobile) {
    &__row {
      flex-direction: column;
    }

    &__radio-group-wrapper {
      margin: 0;
    }

    &__cell {
      display: flex;
      align-items: center;
      width: 100%;
      margin: 0 !important;

      &.switch-cell {
        padding-top: 5px;
      }

      &:not(:first-child) {
        margin-top: 12px !important;
      }
    }
  }

  @include screen-max(362px) {
    &__radio-group {
      flex-direction: column;
      margin-top: 9px;

      > *:not(:first-child) {
        margin-top: 12px;
        margin-left: 0 !important;
      }
    }
  }
}
