@import "SCSS/colors";
@import "SCSS/partials/variables";
@import "SCSS/partials/mixins";

.subheader {
  background-color: #fff;
  box-shadow:
    0 1px 2px 0 rgba(29, 30, 38, 0.05),
    0 3px 10px 0 rgba(29, 30, 38, 0.1);
  box-sizing: border-box;
  left: 0;
  position: fixed;
  top: 60px;
  width: 100%;
  z-index: 2;

  &__container {
    margin: 0 auto;
    max-width: calc(#{$content-width} - 48px);
    padding: 12px 24px;
  }

  &__title {
    @include font(18px, 600, $gray50, 24px, 0.1px);

    &--view-mode {
      color: $gray70;
    }
  }

  &__favorite-icon {
    margin-left: 12px !important;

    svg {
      height: 24px;
      width: 24px;
    }
  }

  &__button {
    margin-left: 12px !important;
  }

  @include screen-max($mobile) {
    &__container {
      align-items: flex-start !important;
      flex-direction: column;
    }

    &__title {
      font-size: 16px;
      margin-bottom: 12px;

      > span {
        @include line-clamp(1);
      }
    }

    &__button {
      margin-left: 0;

      .uui-caption {
        font-size: 12px;
      }
    }
  }
}

@include screen-max($mobile) {
  .subheader + div {
    margin-top: 96px !important;
  }
}
