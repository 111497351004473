.expandable-menu {
  margin-top: 22px;

  &:first-child {
    margin-top: 0px;
  }

  .chevron-icon {
    margin-right: 6px;
  }

  &__items {
    margin-top: 6px;
    padding-left: 25px;
  }

  &.TimePeriod {
    .expandable-menu__items {
      margin-top: 12px;
    }
  }
}
