@import "SCSS/colors";
@import "SCSS/partials/variables";

.dashboard-domain-filters {
  &__input {
    height: $dashboard-search-height !important;
    max-width: 300px;
    padding: 9px 19px !important;
    margin-right: 6px;
    border-radius: 6px !important;
    border-color: $gray40 !important;
    border-style: solid !important;
    border-width: 1px !important;
    box-shadow: $dashboard-shadow;

    svg {
      fill: $light-blue;
    }

    &:hover {
      border-color: $sky !important;
    }
  }
}
