$large-notification-width: 474px;
$content-width: 1440px;
$tablet: 991px;
$mobile: 640px;
$card-box-shadow:
  0 1px 2px 0 rgba(29, 30, 38, 0.1),
  0 2px 10px 0 rgba(29, 30, 38, 0.15);
$dashboard-shadow:
  0px 3px 6px 0px rgba(29, 30, 38, 0.1),
  0px 0px 3px 0px rgba(29, 30, 38, 0.05);
$wall-height: 116px;
$dashboard-search-height: 56px;
$dashboard-filter-role-width: 137px;
$all-reports-search-width: 1183px;
