@import "./mixins";

.sticky {
  position: sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
}

.absolute {
  position: absolute;
}

.cut-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.capitalize {
  text-transform: capitalize;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-wait {
  cursor: wait;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.overflow-hidden {
  overflow: hidden;
}

.unselect {
  /* stylelint-disable */
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* stylelint-enable */
}

.btn {
  border-color: $gray50;
  color: $gray60;
  padding: 0 12px;

  &--grass {
    border-color: #67a300;
    color: #fff;
  }

  &--sky {
    border-color: $sky;
    color: #fff;
  }
}

.svg-24 {
  svg {
    width: 24px;
    height: 24px;
  }
}

.svg-hover:hover {
  svg,
  path {
    fill: $light-blue;
  }
}

.form {
  display: flex;
  flex-direction: column;

  &__row {
    align-items: flex-start;
    margin-bottom: 12px;

    > *:not(:last-child) {
      margin-right: 12px;
    }

    > *:not(:first-child) {
      margin-left: 12px;
    }

    .max-grow-half {
      flex-basis: calc(50% - 12px) !important;
    }
  }

  &__block-title {
    @include font(18px, 600, null, 24px, null);

    margin: 0 0 18px;
  }
}

.highlight {
  background: $highlight-color;
  border-radius: 3px;
  width: fit-content;
  box-decoration-break: clone;
}

.one-line {
  @include line-clamp(1);

  padding-bottom: 2px;
}

.tooltip-whitespace-pre-line {
  .uui-tooltip-body {
    white-space: pre-line;
  }
}

.shrink-0 {
  flex-shrink: 0;
}

// Padding
.p-0 {
  padding: 0;
}

// Flex
.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

// Flex Wrap
.flex-wrap {
  flex-wrap: wrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.flex-no-wrap {
  flex-wrap: nowrap;
}

// Flex Direction
.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

// Justify Content
.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

// Align Items
.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.items-stretch {
  align-items: stretch;
}

// Align Self
.self-auto {
  align-self: auto;
}

.self-start {
  align-self: flex-start;
}

.self-end {
  align-self: flex-end;
}

.self-center {
  align-self: center;
}

.self-stretch {
  align-self: stretch;
}
