@import "SCSS/colors";

.view-report-button {
  min-height: unset !important;
  fill: $gray60 !important;
  margin-left: 6px;
  flex-shrink: 0;

  &:hover {
    fill: $light-blue !important;
  }
}
