@import "SCSS/partials/variables";
@import "SCSS/partials/mixins";

.dashboard-news-details {
  &__row {
    margin-bottom: 18px;

    &:last-child {
      margin-bottom: 6px;
    }
  }

  &__label {
    .uui-label {
      margin-bottom: 2px !important;
    }
  }

  &__text {
    &--description {
      white-space: pre-line;
    }
  }
}
