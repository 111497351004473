@import "SCSS/colors";
@import "SCSS/partials/variables";
@import "SCSS/partials/mixins";

$filter-header-height: 96px;
$filter-footer-height: 47px;

.reports-browser-filter {
  display: flex;
  position: relative;

  &__block {
    border-top: 1px solid $gray30;
    flex-grow: 1;
    height: calc(100% - #{$filter-header-height} - #{$filter-footer-height});
    max-height: calc(100% - #{$filter-header-height} - #{$filter-footer-height});
    overflow: hidden;
    padding: 24px;
    position: relative;

    &--top {
      border-top: 0;
      flex-grow: 0;
      height: $filter-header-height;
      padding: 22px;
    }

    &--content {
      padding: 0;
    }
  }

  &__toggle-button {
    background-color: $gray20 !important;
    border-color: $gray50 !important;
    color: $gray60 !important;
    flex-shrink: 0;

    .uui-icon {
      fill: $gray60;
    }

    &--opened,
    &:hover {
      background-color: $certified-tag-blue !important;
      border-color: $certified-tag-blue !important;
      color: #fff !important;

      .uui-icon {
        fill: #fff;
      }
    }

    &:focus {
      box-shadow: none !important;
    }
  }

  &__modal {
    display: none;
    height: calc(100vh - 150px);
    left: 0;
    position: absolute;
    top: 56px;
    width: 330px;
    z-index: 1;

    &--show {
      display: block;
    }

    &--cards#{&}--synonymWords {
      top: 83px;
    }

    &--table#{&}--synonymWords {
      top: 65px;
    }

    .modal-panel {
      border-radius: 6px;
      box-shadow:
        0 1px 2px 0 rgba(29, 30, 38, 0.05),
        0 3px 10px 0 rgba(29, 30, 38, 0.1);
      height: 100%;
    }
  }

  &__checkbox-group {
    .uui-checkbox {
      align-self: center !important;
      height: 12px !important;
      width: 12px !important;

      svg {
        height: 10px !important;
        width: 12px !important;
      }
    }

    .uui-input-label {
      font-size: 14px;
      line-height: 24px !important;
    }
  }

  &__sections {
    display: flex;
    flex-direction: column;
    height: 100%;

    .filters-search {
      height: 30px;
      margin-top: 24px;
      min-height: 30px !important;
    }

    .sections-wrapper {
      margin: 18px 24px;

      .checkbox-item {
        margin-top: 22px;
        padding-left: 5px;

        /* stylelint-disable */
        &:first-child {
          margin-top: 16px;
        }

        &:last-child {
          margin-bottom: 10px;
        }
        .uui-input-label {
          margin-left: 12px !important;
        }
        /* stylelint-enable */
      }
    }
  }

  &__submit {
    display: inline-block !important;
  }

  &__close,
  &__collapse-all {
    margin-left: 10px !important;
  }

  &-time-period__date-picker {
    &--from-date {
      margin-right: 6px;
    }

    &--to-date {
      margin-left: 6px;
    }
  }

  &-time-period__date-picker-label {
    @include font(14px, 600, $gray70, 18px);
  }

  &-time-period__date-picker-input {
    margin-top: 6px;
    width: 122px !important;

    .uui-icon > svg {
      fill: $gray40;
    }
  }

  @include screen-max($mobile) {
    &__modal {
      width: calc(100vw - 48px);
      top: 36px !important;
    }
  }
}
