@import "SCSS/colors";
@import "SCSS/partials/mixins";
@import "SCSS/partials/variables";

.dashboard-block {
  --gap: 24px;

  position: relative;
  min-height: calc(2 * var(--gap) + 30px);
  margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));
  width: calc(100% + var(--gap));

  &__title {
    .uui-caption {
      @include font(21px, 600, $night700, 24px);
    }

    &--isDisabled {
      .uui-caption {
        pointer-events: none;
        color: $night500;
      }
    }

    &:hover {
      .uui-caption {
        text-decoration: underline;
      }
    }
  }

  &__loader {
    position: absolute;
    z-index: 1;
    left: calc(50% - 45px);
  }

  &__report {
    position: relative;
    width: 330px;
    margin: var(--gap) 0 0 var(--gap);
    background-color: #fff;
    border-radius: 6px;
    box-shadow: $dashboard-shadow;

    .drag-handle {
      position: absolute;
      left: -1px;
      top: 18px;
      padding: 0px 2px;
      opacity: 0;
      transition: opacity 0.3s;

      svg {
        fill: $gray50;
      }
    }

    &--dragOverlay {
      box-shadow: 0 0 6px 2px $sky;
    }

    &--active {
      opacity: 0.5;
    }

    &:nth-child(n + 4) {
      margin-top: 10px;
    }

    &:hover {
      .drag-handle {
        opacity: 1;
      }
    }
  }

  @include screen-max(1439px) {
    &__report:nth-child(n + 3) {
      margin-top: 10px;
    }
  }

  @include screen-max($tablet) {
    &__report {
      @include dashboard-tablet-draggable-block;

      .drag-handle {
        opacity: 1;
      }
    }
  }

  @include screen-max($mobile) {
    &__report {
      width: 100%;

      &:nth-child(n + 2) {
        margin-top: 10px;
      }
    }
  }
}
