/* Gray */
$gray5: #fafafc;
$gray10: #f5f6fa;
$gray20: #ebedf5;
$gray30: #e1e3eb;
$gray40: #ced0db;
$gray50: #acafbf;
$gray60: #6c6f80;
$gray70: #474a59;
$gray80: #303240;
$gray90: #1d1e26;

/* Night scale */
$night50: #f2f3f7;
$night100: #eaedf5;
$night200: #d9dbe3;
$night300: #c0c3ce;
$night400: #a8a9b4;
$night500: #868996;
$night600: #6c7283;
$night700: #525462;
$night800: #494e5c;
$night900: #2c2f3c;

/* Blue badge */
$blue: #008abd;
$sky: #30b6dd;
$light-blue: #c4eaf5;
$white-blue: #f5fcfd;

/* Amber badge */
$amber: #e06900;
$light-amber: #ffe4ad;
$white-amber: #fffaf0;

/* Grass badge */
$grass: #558a00;
$light-grass: #daefa9;
$white-grass: #f6ffe5;

/* Custom colors */
$button-text-color: #8a8c99;
$light-blue: #009ecc;
$light-blue-hover: #008abd;
$certified-tag-blue: #219ecd;
$favorite-tag-orange: #fbab30;
$highlight-color: #ffe4ad;
$wall-color: #474a59;
