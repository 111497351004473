@import "SCSS/colors";

.dashboard-card-footer {
  border-top: 1px solid $gray30;
  padding: 0 10px 0 9px;
  height: 36px;

  &__favorite-icon {
    svg {
      height: 18px;
      width: 18px;
    }

    &:hover {
      svg {
        fill: $light-blue;
      }
    }
  }

  &__view-btn {
    padding: 0 9px !important;
    border-radius: 3px;

    .uui-icon {
      svg {
        width: 12px !important;
        height: 12px !important;
      }
    }

    &:hover {
      background-color: rgba(225, 244, 250, 1) !important;
    }
  }

  .report-buttons {
    margin-right: 0;
  }
}
