@import "SCSS/colors";

.report-buttons {
  min-height: unset !important;
  margin-right: auto;

  .uui-button-box {
    &:hover {
      path {
        fill: $light-blue-hover;
      }
    }
  }

  &__report-kb-link {
    margin-left: 10px !important;

    svg {
      width: 16px;
    }
  }

  .report-context-menu-icon {
    margin-left: 5px;
  }
}
