.select {
  &__wrapper {
    position: relative;

    .uui-input-box {
      > div:first-child {
        width: calc(100% - 62px);
      }
    }
  }

  &__loader {
    position: absolute;
    top: calc(50% - 10px);
    right: 18px;

    &--right-30 {
      right: 14px;
    }
  }

  &__clone-icon {
    position: absolute !important;
    top: calc(50% - 10px);
    right: 46px;
  }
}
