@import "SCSS/colors";
@import "SCSS/partials/mixins";
@import "SCSS/partials/variables";

.report {
  &__container {
    display: flex;
    flex-direction: column;
    margin: 24px;
  }

  &__actions-row {
    margin-bottom: 25px;

    .uui-button-box {
      min-width: 24px;
    }
  }

  &__favorite-icon {
    margin-right: 10px !important;
  }

  &__certified-icon {
    margin-right: 9px;
  }

  &__report-name {
    @include font(24px, 600, $light-blue, 30px);

    margin-right: 16px;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: $light-blue-hover;
    }

    &--disabled {
      pointer-events: none;
      cursor: auto;

      &:hover {
        color: $light-blue;
      }
    }
  }

  &__copy-icon,
  &__file-icon {
    margin-right: 8px !important;
  }

  &__kb-icon {
    margin-right: 10px !important;
  }

  &__row {
    margin-bottom: 18px;
    min-height: 24px !important;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__label {
    .uui-label-left {
      align-items: flex-start !important;

      > div:first-child {
        flex-basis: 197px;
        flex-shrink: 0;

        .uui-label {
          padding: 0;
        }
      }

      .uui-typography {
        line-height: 24px;
      }
    }

    .report-text {
      display: flex;
      padding: 0;

      &--serverPath {
        margin-right: 8px;
        max-width: 65%;
      }

      &--description {
        flex-basis: 622px;
        white-space: pre-line;
      }

      &__thumb-icon {
        margin-left: 18px;
        position: relative;
        top: -2px;
      }
    }

    .copy-link {
      height: 24px;
      min-height: 24px !important;

      .uui-caption {
        align-items: center;
        display: flex;
        padding: 0 6px;
      }
    }

    .report-link {
      min-height: 24px !important;

      .uui-caption {
        padding: 0;
      }
    }

    .report-badges {
      --gap: 6px;

      margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));

      .badge {
        margin: var(--gap) 0 0 var(--gap);

        /* stylelint-disable-next-line max-nesting-depth */
        svg {
          padding-left: 2px;
          margin-bottom: -2px;
          fill: $light-blue;
        }
      }

      .report-badge--label {
        font-size: 14px;
        line-height: 24px;
        padding: 0 5px;
      }
    }

    &--align-center {
      .uui-label-left {
        align-items: center !important;
      }
    }
  }

  @include screen-max($mobile) {
    &__actions-row {
      flex-wrap: wrap;
      margin-bottom: 16px;

      > * {
        order: 2;
      }
    }

    &__favorite-icon {
      margin-right: 9px;
    }

    &__certified-icon {
      margin-right: 12px;
    }

    &__report-name {
      order: 1;
      width: 100%;
      margin-bottom: 12px;
    }

    &__label {
      .uui-label-left {
        flex-direction: column;
        align-items: flex-start !important;

        > div:first-child {
          flex-basis: unset;
        }
      }

      .report-text,
      .report-text--description {
        flex-basis: 100%;
      }

      .report-text__thumb-icon {
        top: 2px;
        align-self: flex-start !important;
      }

      .report-link {
        width: 100%;
      }

      .report-link > .uui-caption {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .owners__container {
        align-self: flex-start;
      }
    }
  }
}
