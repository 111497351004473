@import "SCSS/colors";

.report-view-toggle {
  border-radius: 3px;
  flex-wrap: nowrap !important;

  &__icon {
    background: $gray30 !important;
    fill: $gray50 !important;
    padding: 8px !important;

    &:hover {
      opacity: 0.8;
    }

    svg {
      height: 20px;
      width: 20px;
    }

    &--active {
      background: $light-blue !important;
      fill: #fff !important;
    }
  }
}
