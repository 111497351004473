.primary-role-modal {
  &__content {
    .primary-role-modal__info {
      padding-bottom: 30px;
    }
  }

  &__title {
    border-bottom: 1px solid #ced0db;
  }
}
