@mixin font($size: 14px, $weight: 400, $color: null, $lineHeight: null, $letterSpacing: null) {
  color: $color;
  font-family: "Source Sans Pro", sans-serif;
  font-size: $size;
  font-weight: $weight;
  letter-spacing: $letterSpacing;
  line-height: $lineHeight;
}

@mixin line-clamp($lineCount) {
  display: block;
  max-height: calc(var(--lh) * #{$lineCount});

  @supports (display: -webkit-box) and (-webkit-box-orient: vertical) and (-webkit-line-clamp: $lineCount) {
    /* stylelint-disable */
    display: -webkit-box;
    -webkit-line-clamp: $lineCount;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: none;
    /* stylelint-enable */
  }
}

@mixin screen-max($width) {
  @media screen and (max-width: $width) {
    @content;
  }
}

@mixin dashboard-tablet-draggable-block {
  transform: scale(1);
  transition: box-shadow 200ms cubic-bezier(0.18, 0.67, 0.6, 1.22);

  &--dragOverlay {
    width: calc(50vw - 36px);
    animation: pop 200ms cubic-bezier(0.18, 0.67, 0.6, 1.22);
  }
}

@mixin search-input {
  &__input {
    border-color: $gray40 !important;

    .uui-icon:first-child > svg {
      fill: $gray40;
    }

    &:focus,
    &:focus-within {
      box-shadow: none !important;
      outline: none;
    }
  }
}
