@import "SCSS/partials/mixins";
@import "SCSS/colors";

.owners {
  &__container {
    align-self: center;

    .comma-separated {
      &:not(:last-child)::after {
        content: ", ";
      }
    }

    .space-separated {
      &:not(:last-child)::after {
        content: " ";
      }
    }
  }

  &__link {
    @include font(14px, 400, $gray70);

    text-decoration: none;

    &:hover {
      color: $light-blue-hover !important;
    }
  }
}
