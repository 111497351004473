@import "SCSS/colors";
@import "SCSS/partials/variables";
@import "SCSS/partials/mixins";

.filter-role-toggler {
  box-sizing: border-box;
  max-width: 300px;
  min-width: $dashboard-filter-role-width;
  cursor: pointer;
  padding-left: 6px;
  display: flex;
  align-items: center;
  user-select: none;

  &__text {
    font-family: "Sans Regular", Arial, sans-serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    color: $light-blue;
    padding-right: 6px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &__placeholder {
    color: $gray50;
    font-weight: 400;
  }

  &__body {
    width: 100%;
    display: flex;
  }

  &__badge {
    margin: 2px 10px 0 8px !important;
    box-sizing: border-box;
    height: 20px !important;
    min-width: 34px !important;
    border-radius: 30px !important;
    border: 1px solid $gray30 !important;

    .uui-caption {
      font-size: 16px !important;
      font-weight: 600 !important;
      line-height: 17px !important;
      padding: 0 3px !important;
    }
  }

  &__spinner {
    &-box {
      flex-grow: 1;
    }

    .uui-spinner-dot {
      height: 9px !important;
      width: 9px !important;
    }
  }

  @include screen-max(767px) {
    &__spinner {
      .uui-spinner-dot {
        height: 5px !important;
        width: 5px !important;
      }
    }

    .uui-input-box {
      min-width: unset !important;

      .uui-input {
        font-size: 14px !important;
        min-width: 30px;
      }
    }
  }
}
