.roles-field {
  &__dropdown-item {
    padding: 7px 0;
    width: 100%;
  }

  &__thumb-up {
    padding: 3px;
  }

  &__thumb-up.not-active {
    display: none;
  }

  &__input-item {
    cursor: default !important;
    background-color: #e1e3eb;
    border-radius: 30px;
    padding: 1px 6px;
    margin: 0 3px 2px 0;

    &-thumb-up {
      margin-bottom: 3px;
      width: 15px;
      height: 14px;
    }

    &-remove {
      cursor: pointer;
      padding: 4px 2px 0 0;
      width: 10px;
      height: 15px;
    }

    &-text {
      padding: 0 6px !important;
      font-size: 12px;
    }
  }
}
