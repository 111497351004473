@import "SCSS/index";

.report-table {
  background-color: #fff;
  border-radius: 6px;
  color: $night700;
  font-size: 14px;
  line-height: 18px;
  margin: 20px 0;
  width: 100%;

  &__header {
    padding: 15px 18px;
  }

  &__content {
    width: 100%;
  }

  &__not-found-content {
    padding: 40px;

    &-loop {
      align-self: center !important;
      margin-bottom: 5px;

      svg {
        height: 40px;
        width: 40px;
      }
    }
  }

  &__columns-headers {
    background-color: $gray5;
    border-bottom: 1px solid $gray30;
    border-top: 1px solid $gray30;
    display: flex;
    justify-content: space-between;
  }

  &__column-header {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 12px;
    font-weight: 600;
    overflow: hidden;

    &-content {
      display: flex;
      padding: 8px 5px;
      text-transform: uppercase;
    }

    .icon-wrapper {
      padding-left: 3px;
    }

    &--sortable {
      cursor: pointer;
    }

    &--favorite {
      display: flex;
      justify-content: center;

      .icon-wrapper {
        padding-left: 0;
      }
    }

    &:hover {
      background-color: $gray20;

      .icon-wrapper svg {
        fill: $gray50;
      }
    }
  }
}

.report-table-row {
  border-bottom: 1px solid $gray30;
  color: $night700;
  display: flex;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  justify-content: space-between;
  min-height: 66px;
  overflow: hidden;
  position: relative;

  .owners__link {
    color: $night700;
  }

  .cell-content {
    overflow: hidden;
    overflow-wrap: anywhere;
    padding: 9px 5px;

    &.blue-text {
      color: $light-blue;
    }

    &.badges {
      display: flex;
      flex-wrap: wrap;

      > * {
        margin: 0 auto 6px 0;
      }
    }
  }
}

.report-table-column {
  /* stylelint-disable */
  &-favorite {
    flex-basis: 3.6%;

    .cell-content {
      padding: 12px 5px;
    }

    .star-icon {
      svg {
        height: 15px;
        width: 15px;
      }
    }

    .certified-icon {
      svg {
        height: 13px;
        width: 13px;
      }
    }
  }

  &-name {
    flex-basis: 16.8%;

    .cell-content {
      width: 100%;

      > .report-link {
        margin-right: 6px;

        .uui-caption {
          text-align: left;
          font-family: "Source Sans Pro", sans-serif;
          line-height: 24px;
        }
      }
    }

    .report-buttons {
      margin-left: auto;
      margin-right: unset;

      &__report-kb-link,
      .view-report-button,
      .report-context-menu-icon {
        padding-top: 4px;
      }

      &__report-kb-link {
        margin-left: 7px;
      }

      .view-report-button {
        margin-left: 0px;
      }
    }
  }
  /* stylelint-enable */

  &-description {
    flex-basis: 26%;

    .cell-content {
      > span {
        @include line-clamp(3);

        font-size: 12px;
      }
    }
  }

  &-owner {
    flex-basis: 7.8%;
  }

  &-role {
    flex-basis: 9.6%;
  }

  &-domain {
    flex-basis: 9.6%;
  }

  &-app {
    flex-basis: 8.6%;
  }

  &-metrics {
    flex-basis: 9.6%;
  }

  &-date {
    flex-basis: 8%;
  }

  &-found {
    flex-basis: 17%;
    padding-left: 18px;
    padding-bottom: 15px;
  }
}
