.context-menu-btn {
  .uui-text {
    color: #6c6f80;
  }

  svg {
    fill: #6c6f80;
  }

  &:hover {
    background-color: #f8f8f8;
    cursor: pointer;
  }
}
