@import "SCSS/colors";
@import "SCSS/partials/variables";
@import "SCSS/partials/mixins";

.version-history-modal {
  &__content {
    display: flex;
    justify-content: center;
    padding: 0;
    min-height: 300px;
  }
}

.version-history {
  display: flex;

  &__diff-container {
    @include font(14px, 400, $gray70, 16px);

    padding-left: 88px;
    padding-right: 24px;
  }

  &__diff-item {
    &:not(:last-child) {
      margin-bottom: 4px;
    }
  }

  &__diff-item-label {
    flex-basis: 105px;
    margin-right: 24px;
  }
}

@include screen-max($mobile) {
  .version-history {
    &__diff-container {
      font-size: 12px;
    }
  }
}
