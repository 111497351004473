@import "SCSS/colors";
@import "SCSS/partials/variables";
@import "SCSS/partials/mixins";

.upload-file-field-badge {
  align-items: center;
  border: 1px solid $gray40;
  border-radius: 6px;
  box-sizing: border-box;
  display: flex;
  height: 48px;
  margin-top: 12px;
  padding: 6px 10px 6px 6px;
  width: 176px;

  &.not-valid {
    border-color: red;
  }

  &__info {
    width: calc(100% - 48px);

    &-name {
      @include font(14px, 400, $night700, 18px);
    }

    &-type-size {
      @include font(12px, 400, $gray50, 18px);

      text-transform: uppercase;
    }
  }

  &__remove-icon {
    align-self: flex-start;
    margin-left: auto;
  }

  &__extension-icon {
    margin-right: 6px;

    > svg {
      height: 24px;
      width: 24px;
    }

    &--xlsx > svg {
      fill: #1e8f2f;
    }

    &--docx > svg {
      fill: #325abd;
    }

    &--pdf > svg {
      fill: #d2231a;
    }
  }
}
