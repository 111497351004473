@import "SCSS/colors";
@import "SCSS/partials/mixins";
@import "SCSS/partials/variables";

.dashboard {
  margin-top: 32px;

  &__search-row {
    position: relative;
    min-height: 0px !important;
  }

  &__loader-icon {
    left: calc(50% - 54px - 180px);
    opacity: 0.5;
    position: fixed;
    top: calc(50% - 54px);
    z-index: 100;

    > svg {
      height: 108px;
      width: 108px;
    }
  }

  &__sidebar {
    margin-left: 9px;

    &-panel {
      padding: 24px;
      box-shadow: $dashboard-shadow !important;
    }

    &-title {
      @include font(24px, 600, $gray70, 30px);
    }

    &-add-icon {
      border: 1px solid $sky;
      border-radius: 3px;

      &:hover {
        border: 1px solid $light-blue-hover;
      }
    }
  }

  &__main {
    margin-right: 9px;

    .accordion {
      margin-bottom: 40px;
    }
  }

  @include screen-max($tablet) {
    flex-direction: column;

    &__cell {
      margin: 0 !important;
      width: 100%;
    }

    &__main {
      margin-bottom: 24px;
      margin-right: 0;
    }

    &__sidebar {
      margin-left: 0px;
    }

    &__loader-icon {
      left: calc(50% - 54px);
    }
  }
}
