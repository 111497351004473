/* stylelint-disable max-nesting-depth */
@import "SCSS/colors";
@import "SCSS/partials/mixins";
@import "SCSS/partials/variables";

$box-shadow-drag-overlay: 0 0 6px 2px $sky;

@keyframes pop {
  0% {
    transform: scale(1);
    box-shadow: $card-box-shadow;
  }

  100% {
    transform: scale(1.05);
    box-shadow: $box-shadow-drag-overlay;
  }
}

.dashboard-card {
  &__description {
    &-text {
      height: 39px;
      line-height: 18px !important;
      margin: 3px;
      /* stylelint-disable-next-line value-no-vendor-prefix */
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      word-break: break-word;
    }
  }

  &__report {
    height: 114px;

    &-details {
      box-sizing: border-box;
      padding: 0 18px;
      flex-grow: 1;

      .report-link {
        max-width: calc(100% - 25px);

        .uui-caption {
          font-size: 18px;
          line-height: 34px;
        }

        &:last-child {
          max-width: 100%;
        }
      }

      &-name {
        color: $night700 !important;

        .uui-caption {
          line-height: 30px;
          font-size: 18px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: $night700 !important;

          &:hover {
            color: $light-blue !important;
          }
        }
      }

      &-certified {
        margin-left: 8px;

        svg {
          height: 16px;
          width: 16px;
        }
      }
    }
  }

  &__report.full {
    height: 204px;

    .dashboard-card__report-details {
      padding-top: 5px;

      &-name {
        margin: 4px 0;

        .uui-caption {
          line-height: 19px;
        }
      }
    }

    .badges__container {
      margin-bottom: 3px;
    }

    .badge {
      margin-right: 5px;
    }
  }

  @include screen-max($mobile) {
    width: 100%;

    &__report-details-name {
      max-width: calc(100vw - 48px - 40px - 60px);
    }
  }
}
