@font-face {
  font-family: "Sans Regular";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    url("../../assets/fonts/SourceSansPro-Regular.ttf.woff2") format("woff2"),
    url("../../assets/fonts/SourceSansPro-Regular.otf.woff") format("woff");
}

@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    url("../../assets/fonts/SourceSansPro-Regular.ttf.woff2") format("woff2"),
    url("../../assets/fonts/SourceSansPro-Regular.otf.woff") format("woff");
}

@font-face {
  font-family: "Sans Semibold";
  font-style: normal;
  font-display: swap;
  font-weight: normal;
  src:
    url("../../assets/fonts/SourceSansPro-Semibold.ttf.woff2") format("woff2"),
    url("../../assets/fonts/SourceSansPro-Semibold.woff") format("woff");
}

@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src:
    url("../../assets/fonts/SourceSansPro-Semibold.ttf.woff2") format("woff2"),
    url("../../assets/fonts/SourceSansPro-Semibold.woff") format("woff");
}

@font-face {
  font-family: "Sans Bold";
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    url("../../assets/fonts/SourceSansPro-Bold.ttf.woff2") format("woff2"),
    url("../../assets/fonts/SourceSansPro-Bold.otf.woff") format("woff");
}

@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    url("../../assets/fonts/SourceSansPro-Bold.ttf.woff2") format("woff2"),
    url("../../assets/fonts/SourceSansPro-Bold.otf.woff") format("woff");
}

@font-face {
  font-family: "Sans Italic";
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src:
    url("../../assets/fonts/SourceSansPro-It.ttf.woff2") format("woff2"),
    url("../../assets/fonts/SourceSansPro-It.otf.woff") format("woff");
}
