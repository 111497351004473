div.error-page {
  &__title {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 42px;
    line-height: 48px;
    text-transform: uppercase;
  }

  &__subtitle-row {
    align-items: baseline;
    justify-content: center;
  }

  &__subtitle {
    font-size: 18px;
    line-height: 24px;
    margin: 0 0 24px;
  }
}

.error-page__link {
  .uui-caption {
    font-size: 18px;
    line-height: 24px;
    margin: 0 0 24px;
  }
}
