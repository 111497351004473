@import "SCSS/colors";

.dashboard-news-feed {
  margin-top: 30px;
  min-height: 50px;
  position: relative;

  &__spinner {
    inset: 0;
    position: absolute;
  }

  &__item {
    margin-top: 30px;

    &:first-child {
      margin-top: 0;
    }

    &-date,
    &-title,
    &-description {
      text-align: left;
      padding-bottom: 9px !important;
      padding-top: 0 !important;
    }

    &-date {
      color: $gray50 !important;
    }

    &-title {
      color: $night700 !important;

      &:hover {
        color: $sky !important;
      }
    }

    &-description {
      width: calc(100% - 6px);
      white-space: pre-line;
    }

    &-link {
      text-decoration: underline !important;
    }
  }

  &__footer {
    justify-content: space-between;
    margin-top: 20px;

    &-chevron {
      border-color: $gray50 !important;
      padding: 0 !important;

      &--left {
        margin-right: 10px;
      }

      .uui-icon {
        margin-left: 6px;
        margin-right: 6px;

        svg {
          height: 26px;
          width: 26px;
        }
      }
    }

    .uui-disabled {
      background-color: #e1e3eb !important;
    }
  }
}
