@import "SCSS/colors";
@import "SCSS/partials/mixins";

.accordion {
  &__toggler {
    &-chevron-icon {
      margin-right: 6px !important;
    }

    &-title {
      .uui-caption {
        font-size: 21px !important;
        line-height: 24px !important;
      }
    }

    &-badge {
      @include font(12px, 600, $gray60, 18px);

      border-color: $gray30;
      background-color: $gray30;
      border-radius: 9px;
      margin-left: 12px;
      padding: 0 9px;
    }
  }

  &__body {
    margin-top: 18px;
  }

  &--isDisabled {
    .accordion__toggler-title,
    .accordion__toggler-chevron-icon,
    .accordion__toggler-all-reports-link {
      pointer-events: none;
    }

    .accordion__toggler-all-reports-link,
    .accordion__toggler-title {
      color: $night500 !important;
    }

    .accordion__toggler-chevron-icon {
      fill: $gray50;
    }
  }
}
