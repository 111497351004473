@import "SCSS/colors";
@import "SCSS/partials/mixins";
@import "SCSS/partials/variables";

.dashboard-domain-search {
  @include search-input;

  position: absolute;
  left: 0;
  bottom: 100%;
  width: 100%;

  &__input {
    box-shadow: $dashboard-shadow;
    min-height: $dashboard-search-height !important;
    border-radius: 6px !important;
    padding-left: 11px !important;
    padding-right: 9px !important;

    input {
      font-size: 16px !important;
      line-height: 20px !important;
    }

    &:hover {
      border-color: $gray60 !important;
    }

    &:focus,
    &:focus-within {
      border-color: $sky !important;
      box-shadow: $dashboard-shadow !important;
    }

    .uui-input {
      font-size: 16px;
      padding-left: 8px !important;
    }
  }
}
