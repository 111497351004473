@import "SCSS/partials/variables";
@import "SCSS/partials/mixins";
@import "SCSS/colors";

.badges {
  .badge {
    .thumb-up-icon {
      width: 10px;
      height: 10px;
      margin-right: 2px;
      fill: $light-blue;
    }
  }

  &__container {
    overflow: hidden;
  }

  &__badges-tooltip,
  &__description-tooltip {
    .uui-tooltip-body {
      position: relative;
      box-shadow: $card-box-shadow !important;
    }

    .uui-tooltip-arrow {
      display: none;
    }
  }

  &__badges-tooltip {
    &[data-placement="top-start"] {
      .uui-tooltip-body {
        top: 6px;
      }
    }

    &[data-placement="bottom-start"] {
      .uui-tooltip-body {
        top: -6px;
      }
    }
  }

  &__description-tooltip {
    &[data-placement="top-start"] {
      .uui-tooltip-body {
        top: 11px;
        left: 7px;
      }
    }

    &[data-placement="bottom-start"] {
      .uui-tooltip-body {
        top: -11px;
        left: 7px;
      }
    }

    &--large[data-placement="top-start"],
    &--large[data-placement="bottom-start"] {
      .uui-tooltip-body {
        left: 12px;
      }
    }
  }

  &__badges-panel {
    line-height: 25px;
    max-width: 430px;
    padding: 12px 9px;

    .badge {
      margin-right: 5px;

      .thumb-up-icon {
        width: 10px;
        height: 10px;
        margin-right: 2px;
        fill: $light-blue;
      }
    }
  }

  &__description-panel {
    padding: 12px;
    max-width: 446px;
    font-size: 12px;
    white-space: pre-line;
  }

  @include screen-max($mobile) {
    &__description-tooltip {
      .uui-tooltip-arrow {
        display: block;
      }
    }

    &__description-tooltip[data-placement="top-start"] {
      .uui-tooltip-body {
        top: 6px;
      }

      .uui-tooltip-arrow {
        top: calc(100% + 6px) !important;
      }
    }

    &__description-tooltip[data-placement="bottom-start"] {
      .uui-tooltip-body {
        top: -6px;
      }

      .uui-tooltip-arrow {
        bottom: calc(100% + 6px) !important;
      }
    }

    &__badges-panel {
      max-width: 300px;
    }

    &__description-panel {
      max-width: 200px;
    }
  }
}
