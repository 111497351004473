@import "~@epam/assets/scss/loveship-colors";
@import "./colors";
@import "./partials/fonts";
@import "./partials/variables";
@import "./partials/mixins";
@import "./partials/main";
@import "./partials/uui-lab.overwrites";
@import "./partials/defaults";
@import "~rc-tree/assets/index.css";

.uui-input-box {
  .uui-tag {
    border-radius: 30px !important;
  }
}
