@import "SCSS/colors";
@import "SCSS/partials/mixins";

.expanded-text {
  margin: 6px 0;
  overflow: hidden;
  position: relative;
  width: 100%;

  &--isClosed {
    .expanded-text__content {
      @include line-clamp(3);
    }
  }

  .expanded-text__content {
    @include font(14px, 400, $night700, 18px);

    padding: 0;
  }

  .expanded-text__action-button {
    color: $gray50 !important;
    cursor: pointer;

    &--expand {
      background-color: #fff;
      bottom: 0;
      padding: 0 0 0 4px;
      position: absolute;
      right: 0;
    }
  }
}
