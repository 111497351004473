@import "SCSS/colors";
@import "SCSS/partials/variables";
@import "SCSS/partials/mixins";

.report-cards {
  &__not-found-content {
    padding: 40px;

    &-loop {
      align-self: center !important;
      margin-bottom: 5px;

      svg {
        height: 40px;
        width: 40px;
      }
    }
  }
}

.report-card {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: $card-box-shadow;
  margin-bottom: 12px;
  padding: 16px;

  .badge .thumb-up-icon {
    width: 10px;
    height: 10px;
    margin-right: 2px;
    fill: $light-blue;
  }

  &__icons {
    place-self: flex-start center;
    margin-top: 8px;

    .star-icon svg {
      text-align: center;
      width: 16px;
    }

    .certified-icon svg {
      margin-top: 4px;
      padding-left: 2px;
      text-align: center;
      width: 13px;
    }
  }

  &__report-link {
    .uui-caption {
      font-size: 16px !important;
      text-align: left;
    }
  }

  .report-buttons {
    margin-left: 0 !important;

    &__report-kb-link,
    .view-report-button {
      margin-left: 6px;
      margin-right: 3px;
    }

    .video-guide-icon {
      margin-left: 6px;
    }

    .report-context-menu-icon {
      margin-left: 7.5px;
    }
  }

  &__labeled-field {
    display: inline-block;
    width: auto !important;

    .uui-label {
      color: $gray50 !important;
      flex-shrink: 0;
      font-family: "Source Sans Pro", sans-serif !important;
    }
  }

  &__owner-date-row {
    align-items: flex-start !important;

    .report-card__labeled-field {
      &-text {
        padding: 0;
      }

      &:first-child {
        margin-right: 18px;
        max-width: calc(100% - 153px);
      }
    }
  }

  &__badges-row {
    flex-wrap: wrap;
  }

  .report-card__badges-row {
    > .report-card__labeled-field,
    > .report-card__labeled-field.uui-label,
    > .badge {
      margin-left: 0;
    }

    .report-card__labeled-field.last-group-element,
    .badge.badge--last-group-element {
      margin-right: 15px;
    }
  }

  @include screen-max($tablet) {
    &__owner-date-row {
      flex-wrap: wrap;
      min-height: unset !important;
      margin-bottom: 6px;

      .report-card__labeled-field {
        margin-left: 0 !important;
        max-width: 100% !important;
      }

      .uui-label-left {
        padding: 0 !important;
      }
    }
  }

  @include screen-max($mobile) {
    &__owner-date-row {
      flex-direction: column;

      .report-card__labeled-field {
        margin-right: 0 !important;
      }
    }

    &__badges-row {
      .badge {
        margin-top: 2px;
        margin-bottom: 2px;
      }
    }
  }
}
