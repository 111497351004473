@import "../../scss/partials/variables";
@import "SCSS/colors";

.header {
  &__wrapper {
    z-index: 2 !important; // ANOTHER CASE THE UUI LIB CLASSES ARE OVERRIDING THIS STYLEs
  }

  &__container {
    align-items: center;
    display: flex;
    margin: auto;
    max-width: $content-width;
    width: 100%;

    /* stylelint-disable */
    a[href="/"] > img {
      margin: 0 15px 0 7px;
      width: 145px;
      height: 40px;
    }
    /* stylelint-enable */

    a.header__menu-button {
      background-color: inherit !important;

      &.uui-active,
      &:hover {
        color: #009ecc !important;
      }
    }
  }

  &__menu-button.external-link {
    &:focus {
      color: #fff;
      fill: #fff;
    }

    &:hover {
      color: $light-blue;
      fill: $light-blue;
    }
  }

  &__sp-button {
    background-color: $gray80 !important;
    border: 1px solid #acafc0 !important;
    color: #686a7c !important;

    &:hover {
      background-color: $gray90 !important;
    }

    &:active {
      background-color: $gray80 !important;
    }
  }

  &__add-button {
    margin-left: 18px !important;
  }
}
