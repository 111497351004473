@import "SCSS/partials/variables";
@import "SCSS/partials/mixins";

.virtual-list {
  &--table {
    .virtual-list__items {
      background-color: #fff;
    }
  }
}
