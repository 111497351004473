.uui-popper {
  box-shadow: $card-box-shadow;
}

.notification {
  box-sizing: border-box;

  &--large {
    width: $large-notification-width !important;
  }

  &__text {
    max-height: 54px;
    max-width: 300px;
    overflow-wrap: anywhere;
  }

  > div {
    align-items: center;

    > div {
      align-items: center;
      display: flex;
    }

    .uui-button-box {
      text-transform: uppercase;
    }
  }

  @include screen-max($large-notification-width) {
    &--large {
      width: calc(100vw - 48px) !important;
    }
  }
}

.unstyled-link {
  min-height: unset !important;

  .uui-caption {
    padding: 0;
  }
}

.uui-burger-items-visible {
  // stylelint-disable-next-line
  .uui-button-box {
    padding: 12px 24px;

    &:first-child {
      margin-top: 24px;
    }
  }

  .uui-caption {
    font-weight: 600;
    line-height: 24px;
  }

  .uui-active {
    fill: $sky;
  }
}

.uui-label {
  .uui-labeled-input-info-icon {
    fill: $night400 !important;
  }
}
