@import "SCSS/colors";
@import "SCSS/partials/mixins";
@import "SCSS/partials/variables";

.app {
  &__wall {
    background-color: $wall-color;
    height: $wall-height;

    &-content {
      width: 100%;
    }

    &-text {
      @include font(18px, 600, #fff, 24px);

      position: relative;
      bottom: 2px;
      padding-left: 6px;
    }
  }

  &__wall-content,
  &__container {
    box-sizing: border-box;
    margin: 0 auto;
    max-width: $content-width;
    padding: 24px;
  }
}
