.loader {
  background-color: #303240;
  height: 100%;
  left: 0;
  opacity: 0.5;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;

  &__icon {
    left: calc(50% - 54px);
    position: fixed;
    top: calc(50% - 54px);
    z-index: 100;

    > svg {
      fill: #fff;
      height: 108px;
      width: 108px;
    }
  }
}
