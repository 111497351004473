html {
  --lh: 1rem;

  height: 100%;
  line-height: var(--lh);
}

body {
  background-color: #f2f3f7;
  display: flex;
  flex-direction: column;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  margin: 0;
}

code {
  font-family: "source-code-pro", "Menlo", "Monaco", "Consolas", "Courier New", monospace;
}

.app-container {
  box-sizing: border-box;
  margin: 0 auto;
  max-width: #{$content-width};
  padding: 24px;
}

#root {
  flex: 1 0 auto;

  .header__wrapper + * {
    max-height: calc(100% - 60px);
  }
}

.report-context-menu-icon {
  .uui-icon {
    align-items: center;
    display: flex;
    height: 18px;
    width: 18px;
  }

  &:hover,
  &--isOpen {
    fill: $light-blue !important;
  }

  &--isLoading {
    fill: $gray60 !important;

    &:hover {
      fill: $gray60 !important;
    }
  }
}

img[alt="ERROR OCCURRED!"] {
  max-height: 416px;
}
