@import "SCSS/partials/mixins";
@import "SCSS/partials/variables";

.video-guide-modal {
  .uui-modal-window {
    max-height: none !important;
  }

  &__content {
    justify-content: center;
    padding: 0px;
    border-radius: 0px !important;
    min-height: 500px;
  }

  @include screen-max($mobile) {
    &__content {
      iframe {
        width: 100%;
      }
    }
  }
}
